import * as actionTypes from './types';
import axios from "axios";

const API = axios.create({
    baseURL: "https://scdn.monster/"
});

export const fetchConfig = () => dispatch => {
    API.get('/api/config').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_ERROR,
            payload: e
        })
    })
};

export const fetchGames = () => dispatch => {
    API.get('/api/sport/f1').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_SUCCESS,
            payload: data
        })
    }).catch((e) => dispatch({
        type: actionTypes.FETCH_GAMES_ERROR,
        payload: e
    }))

};
//https://streamly.link
export const fetchGame = (league, id) => dispatch => {
    API.get(`/api/sport/f1/race/${id}`).then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAME_SUCCESS,
            payload: data
        })
    }).catch((e) => dispatch({
        type: actionTypes.FETCH_GAME_ERROR,
        payload: e
    }))
};

export const destroyGame = () => dispatch => {
    dispatch({
        type: actionTypes.DESTROY_GAME
    })
};

export const fetchStream = (id) => dispatch => {
    fetch('/api/v1/event/' + id + '/streams').then(res => res.json())//560952
        .then(stream =>
            dispatch({
                type: actionTypes.FETCH_STREAM,
                payload: stream,
                meta: id
            })
        );
};
